import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import './WestPlantChurchHeader.css'


export default() =>{
    const data = useStaticQuery(graphql`
    query WestPlantChurchQuery{
      file(relativePath: { eq: "WestPlantChurch_images/West_Header.jpg" }) {
        childImageSharp {
          fluid (maxWidth:2000,quality:100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
    `)

    return (
        <div className= 'westPlantChurchHeaderContainer'> 
            <BackgroundImage fluid={data.file.childImageSharp.fluid} className='westPlantChurchHeaderBackground'>
                <div className='westPlantChurchHeaderTitle'>
                    <h1>西 區 植 堂</h1> 
                </div>
            </BackgroundImage>
        </div>
        
    )
}




