import React from 'react'
import Navbar from '../components/NavBar/NavBar'
import GlobalFooter from '../components/Footer/GlobalFooter'
import WestPlantChurchHeader from '../components/WestPlantChurch/WestPlantChurchHeader'
import WestPlantChurchContent from '../components/WestPlantChurch/WestPlantChurchContent'

export default () => {
    return (
      <div>
        <div>
          <Navbar />
        </div>
        <div>
          <WestPlantChurchHeader />
        </div>
        <div>
          <WestPlantChurchContent />
        </div>
        <div>
          <GlobalFooter />
        </div>
      </div>
      )
    }