import React from 'react'
import { Button, Col, Row } from 'antd'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from "gatsby"
import './WestPlantChurchContent.css'
import BackgroundImage from 'gatsby-background-image'

export default () =>{
    const data = useStaticQuery ( graphql`
    fragment westPlantChurchImage on File {
        childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
            }
        }
    }
    query westPlantChurchQuery {
        westLogo: file(relativePath: { eq: "WestPlantChurch_images/West_Logo.png" }) {
            ...westPlantChurchImage
        }
        westcard1: file(relativePath: { eq: "WestPlantChurch_images/West_CardBackground1.jpeg" }) {
            ...westPlantChurchImage
        }
        westcard2: file(relativePath: { eq: "WestPlantChurch_images/West_CardBackground2.jpeg" }) {
            ...westPlantChurchImage
        }
    }
    `)
    return(
        <div>
            <div className='desktop'>
            <div className='westPlantContent'>
                <Row className='cardsRowDesktop1' align='middle'>
                    <Col span={9} offset={4}>
                        <p>
                        我們教會多年來在尋求神是否要植堂，二零一九年教會回應神的呼召，決定在Novi/Wixiom/South Lyon一帶植堂，以滿足住在那一帶華人的屬靈需要，將福音和祝福傳給那裏的人。我們中間的九個家庭於二零一九年九月八號被教會差派在底特律西區植堂。
                        </p>    
                        <p>
                        願主繼續恩待引領我們，榮耀歸給至高真神。
                        </p>               
                    </Col>
                    <Col span={6} offset={1}>
                        <Img fluid={data.westLogo.childImageSharp.fluid} />
                    </Col>
                </Row>
            </div>
                <Row className='cardsRowDesktop2' align='middle'>
                    <Col span={6} offset={4}>
                        <BackgroundImage fluid={data.westcard1.childImageSharp.fluid} className='card2Background'>
                            <div className='card2Container'>
                                <h2>主日聚會</h2>
                                <div className='cardsButton'>
                                <Button type="primary" size ={'large'} style={{ background: "#fcbe32", borderColor: "#fcbe32" }}>
                                        <a style={{color:'white'}} href='https://zoom.us/j/4426992606'>成人主日聚會</a>
                                </Button>
                                </div>
                                <div className='cardsButton'>
                                <Button type="primary" size ={'large'} style={{ background: "#fcbe32", borderColor: "#fcbe32" }}>
                                        <a style={{color:'white'}} href='https://us02web.zoom.us/j/5936344649'>兒童主日聚會</a>
                                </Button>
                                </div>
                            </div>
                        </BackgroundImage>
                    </Col>
                    <Col span={6} offset={4}>
                        <BackgroundImage fluid={data.westcard2.childImageSharp.fluid} className='card3Background'>
                            <div className='card3Container'>
                                <h2>小組聚會</h2>
                                <p>週間姊妹聚會 – 週三</p>
                                <p>週六聖樂小組 – zoom</p>
                                <p>週五姊妹小組 – zoom</p>
                                <p>週五弟兄小組 – zoom</p>
                                <p>週五查經小組 – zoom</p>
                            </div>
                        </BackgroundImage>
                    </Col>
                </Row>
            </div>
            <div className='mobile'>
            <Row className='cardsRowMobile1' align='middle'>
                <Col span={20} offset={2}>
                        <p>
                        我們教會多年來在尋求神是否要植堂，二零一九年教會回應神的呼召，決定在Novi/Wixiom/South Lyon一帶植堂，以滿足住在那一帶華人的屬靈需要，將福音和祝福傳給那裏的人。我們中間的九個家庭於二零一九年九月八號被教會差派在底特律西區植堂。
                        </p>
                        <p>
                        願主繼續恩待引領我們，榮耀歸給至高真神。
                        </p>      
                </Col>
            </Row>
            <Row className='cardsRowMobile2' align='middle'>
                <Col span={20} offset={2}>
                    <Img fluid={data.westLogo.childImageSharp.fluid} />
                </Col>
            </Row>

            <Row className='cardsRowMobile3' align='middle'>
                <Col span={20} offset={2}>
                    <BackgroundImage fluid={data.westcard1.childImageSharp.fluid} className='card2Background'>
                        <div className='card2Container'>
                            <h2>主日聚會</h2>
                            <div className='cardsButton'>
                            <Button type="primary" size ={'large'} style={{ background: "#fcbe32", borderColor: "#fcbe32" }}>
                                    <a style={{color:'white'}} href='https://zoom.us/j/4426992606'>成人主日聚會</a>
                            </Button>
                            </div>
                            <div className='cardsButton'>
                            <Button type="primary" size ={'large'} style={{ background: "#fcbe32", borderColor: "#fcbe32" }}>
                                    <a style={{color:'white'}} href='https://us02web.zoom.us/j/5936344649'>兒童主日聚會</a>
                            </Button>
                            </div>
                        </div>
                    </BackgroundImage>
                </Col>
            </Row>
            <Row></Row>
            <Row className='cardsRowMobile4' align='middle'>
                <Col span={20} offset={2}>
                    <BackgroundImage fluid={data.westcard2.childImageSharp.fluid} className='card3Background'>
                        <div className='card3Container'>
                            <h2>小組聚會</h2>
                            <p>週間姊妹聚會 – 週三</p>
                            <p>週六聖樂小組 – zoom</p>
                            <p>週五姊妹小組 – zoom</p>
                            <p>週五弟兄小組 – zoom</p>
                            <p>週五查經小組 – zoom</p>
                        </div>
                    </BackgroundImage>
                </Col>
            </Row>
            </div>
        </div>
    )
}